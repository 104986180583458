import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HallOfFame() {
  // const [] = useState()
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dataAcheivements = [
    {
      des: "News clipping",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/student-awards/News-paper-Clip.png",
      ],
    },
    {
      des: "Ashish Kumar of Class IX B (Current Class X B) has secured 2nd position in Mukhyamantri Vigyan Pratibha Pariksha 2021-22 conducted by Directorate of Education.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/student-awards/Ashish-Kumar.png",
      ],
    },
    {
      des: "Akshat Aayush of Chinmaya Vidyalaya batch 2016 secured AIR 106 in Civil Services Exam - 2021.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/student-awards/Akshat-Ayush.png",
      ],
    },
    {
      des: "Kartik Jha and Priyanshi Swain of session 2021-2022 cleared NEET Examination with 635/720 and 611/720 scores respectively.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/student-awards/Kartik-Jha.png",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/student-awards/Priyanshi-Swain.png",
      ],
    },
  ];

  const dataSchoolAwards = [
    {
      des: "Ms. Archana Soni, Principal, Chinmaya Vidyalaya, New Delhi has been conferred with the prestigious State Teachers’ Award 2024 by Education Department, Govt. of NCT of Delhi. This recognition is a testament of her unwavering dedication, exceptional leadership and it highlights her significant impact on uplifting  the educational landscape of learning and teaching in Delhi.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic4.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic5.jpg",
      ],
    },
    {
      des: "Chinmaya Vidyalaya, Vasant Vihar is Ranked No.1 in New Delhi in North India’s School Ranking under the category Most Effective Blended Learning Model in a survey conducted by Education Today.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409242/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409242/pic2.jpg",
      ],
    },
    {
      des: "Ms. Archana Soni, Principal, Chinmaya Vidyalaya, New Delhi, has been honoured with Education Stalwarts 2024 and Effective Principals of the year 2024 by Education Today for an exemplary contribution in the filed of education.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic4.jpg",
      ],
    },
    {
      des: "Principal Ms Archana Soni has been recognised as the Most Iconic Principal for Excellence and Leadership in Education by Golden Aim Conference and Awards 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420241/pic1.JPG",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420241/pic2.JPG",
      ],
    },
    {
      des: "Chinmaya Vidyalaya is a recipient of overall trophy in the Shrimad Bhagwat Geeta Chanting Competition for the second consecutive year organised by Shankaracharya Sanskrit Mahavidyalaya, Bhartiya Vidya Bhawan.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/feb-24-award-2.jpg",
      ],
    },
    {
      des: "Chinmaya Vidyalaya, New Delhi was presented with the certificate of participation to recognise the contribution in breaking the Guinness World Record by collecting an astounding 437,975 kgs of paper waste. The collection drive was taken in collaboration with Blue Planet Environmental Solutions India Private Limited where over 300 schools participated.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/feb-24-award-1.jpg",
      ],
    },
    {
      des: `Chinmaya Vidyalaya has been adjudged to be among the Most Inspiring and Innovative Schools in multiple categories by ET TECH X School Excellence Awards 2023-24<br/>
      By Brainfeed`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/Award-creative-for-website.jpg",
      ],
    },
    {
      des: `Chinmaya Vidyalaya, New Delhi has been conferred with the Most Digitally Updated School (Delhi) at Star Education Award 2023 held by ESFE in collaboration with the Education Department of Maharashtra. `,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/star-award-pic-dec-23.png",
      ],
    },
    {
      des: ` Principal, Ms. Archana Soni, Chinmaya Vidyalaya, New Delhi has
      been honoured with 50 Effective Principals – 2023 Award for
      exemplary contribution in the field of education by Education
      Today.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/principal-award-1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/principal-award-2.jpg",
      ],
    },
    {
      des: `Education Today accorded Chinmaya Vidyalaya, New Delhi rank 1
      for Unique Classroom Engagement Initiatives in New Delhi, in
      the CBSE Category in the North India’s School Merit Awards
      2023. It is the recognition of Vidyalaya’s unflagging
      commitment towards excellence.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-5-sep-1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-5-sep-2.jpg",
      ],
    },
    {
      des: ` <strong>
      Kudos Chinmaya Vidyalaya, New Delhi for outstanding
      performance. 
    </strong>
    <br />
    <br />
    Chinmaya Vidyalaya, New Delhi is one of the 17 top schools
    that has been rated AAAA+ in the list of day schools in Delhi
    2023 that comes under the National Day schools category by
    Careers360.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/INDIAS-BEST-SCHOOLS-2023.JPG",
      ],
    },
    {
      des: `  On 13th March 2023, Chinmaya Vidyalaya received Spiritual and
      Value Education (SAVE) Award on the occasion of the Spiritual
      and Value Education Day in the Spiritual and Value Education
      Summit. Spiritual and Value Education Summit was jointly
      organised by The Educational Standards and Testing Council of
      India, International Association of Educators for World Peace,
      India Gandhi Technological and Medical Sciences University,
      Indian Institute of Ecology and Environment and Confederation
      of Indian Universities`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-2-march-23.jpeg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-3-march-23.jpg",
      ],
    },
    // {
    //   des: "Spiritual And Value Education (Save) Award",
    //   images: [
    //     // "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-1-march-23.jpg",
    //     "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-2-march-23.jpeg",
    //     "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-3-march-23.jpg",
    //   ],
    // },
    {
      des: `Chinmaya Vidyalaya is a recipient of the "Happiness Award 2022-23" for exemplary leadership in promoting happiness, awarded by Society for Happiness.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward4.jpg",
      ],
    },

    {
      des: `  Chinmaya Vidyalaya ranked No.1 for “Holistic Education” in New
      Delhi, in the CBSE Category for The North India’s School Merit
      Awards. Survey Conducted by Education Today.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/accolades-14-dec-22.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/accolades-14-dec-22-2.JPG",
      ],
    },

    {
      des: "Principal received an award in the category Top 50 Effective Principals for exemplary contributions in the education field conferred by Education Today",
      images: [
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic6.jpg",
      ],
    },
    {
      des: "Principal , Ms. Archana Soni, was presented Principal of the year 2022 by Brainwonders in collaboration with Universal Mentors Association.",
      images: [
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic5.jpg",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received Brainfeed School Excellence Awards in appreciation for committed efforts and innovative approach to groom the young learners with skills for life.",
      images: [
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic9.jpg",
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic1.jpg",
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic3.jpg",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received ‘The National Education and Training Excellence Award 2022’ awarded by the Confederation of Indian Universities.",
      images: [
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic4.jpg",
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic7.jpg",
        " https://chinmayavvdelhi.ac.in/assets/images/awards/school-awards/pic8.jpg",
      ],
    },

    {
      des: "Chinmaya Vidyalaya, New Delhi ranked No. 18 in India and No. 5 in New Delhi 2020 by Education Today Survey in the category – ‘India’s Top 20 CBSE Schools’.",
      images: [
        "https://chinmayavvdelhi.ac.in/images/Blackele/award1.png",
        "https://chinmayavvdelhi.ac.in/images/Blackele/award2.png",
      ],
    },

    {
      des: "The Vidyalaya has received the International Dimension in School Certification 2020-23 conferred by the British Council, New Delhi, for the third time in a row.",
      images: ["https://chinmayavvdelhi.ac.in/images/Blackele/award4.jpg"],
    },

    {
      des: "Chinmaya Vidyalaya, New Delhi Ranked 1st in ‘Challengers Category’, in South-West Delhi Zone, during ‘Times Of India’ survey 2020 for the second consecutive year.",
      images: ["https://chinmayavvdelhi.ac.in/images/Blackele/award3.jpg"],
    },

    {
      des: "Vidyalaya received World Award for Value Education (WAVE) during World Assembly on Value Education on 13th March 2020 in the main auditorium at India International Centre, 40 Lodi Estate, New Delhi.",
      images: [
        "https://chinmayavvdelhi.ac.in/images/Blackele/S10.png",
        "https://chinmayavvdelhi.ac.in/images/Blackele/S11.png",
      ],
    },

    {
      des: "Vidyalaya received Brainfeed Award 2019 on 6th February 2020 in the categories of Best CBSE Schools, Innovative Practices, Inspirational Leadership, Life Skills Education, Safety and Security for enriching the standards in Imparting Excellence Education to the GenNext learners.",
      images: [
        "https://chinmayavvdelhi.ac.in/images/Blackele/S8.png",
        "https://chinmayavvdelhi.ac.in/images/Blackele/S9.png",
      ],
    },

    {
      des: "‘Chinmaya Vidyalaya’ is Ranked No.1 in India under the Top CBSE School - Parameter wise for “Individual Attention to Students” in a survey conducted by EducationToday on 13th December 2019 at The Chancery Pavilion, Bangalore.",
      images: [
        "https://chinmayavvdelhi.ac.in/images/Blackele/S4.png",
        "https://chinmayavvdelhi.ac.in/images/Blackele/S5.png",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received 2019 Global Human Rights Protection Award on 10th December 2019 organised by Indian Institute Of Human Rights at main auditorium, India International Centre, 40 Lodhi Estate, New Delhi-110003.",
      images: ["https://chinmayavvdelhi.ac.in/images/Blackele/S7.png"],
    },

    {
      des: "Chinmaya Vidyalaya received the internationally acclaimed Global Innovative School Awards (GISA), Dubai 2019 on 23rd November 2019.",
      images: ["https://chinmayavvdelhi.ac.in/images/Blackele/S6.png"],
    },

    {
      des: "Chinmaya Vidyalaya received 2nd National School Education Awards 2019 on 15th October 2019 for Excellent School for Excellence in Student Teacher Interaction CMAI Association Of India.",
      images: [
        "https://chinmayavvdelhi.ac.in/images/Blackele/S2.png",
        "https://chinmayavvdelhi.ac.in/images/Blackele/S3.png",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received an Award in the category of India’s Top 50 Prestigious School Jury Awards 2019 : “Great Legacy in Education (K-12)” by Education Today.",
      images: ["https://chinmayavvdelhi.ac.in/images/Blackele/S1.png"],
    },
  ];

  const dataTeacherAwards = [
    {
      des: "Ms. Anila S. Nath (PGT Economics) and Ms. Asha Shahi (TGT Sanskrit) are proud recipients of National Teacher Award 2019 conferred by Centre for Education Development Foundation.",
      images: [
        {
          img: "https://chinmayavvdelhi.ac.in/images/Blackele/T1.png",
          des: "National Creative Teacher Award Ms. Anila S. Nath (PGT Economics)",
        },
        {
          img: "https://chinmayavvdelhi.ac.in/images/Blackele/T2.png",
          des: "National Innovative Teacher Award Teaching Ms. Asha Shahi (TGT Sanskrit)",
        },
      ],
    },

    {
      des: "Mr. Pratap Singh Bisht, Physical Education Teacher of the Vidyalaya received the Best Coach Award Category for the Dr. Ajmer Singh Award.",
      images: [
        {
          img: "https://chinmayavvdelhi.ac.in/assets/ajmeraward1.png",
          des: "",
        },
        {
          img: "https://chinmayavvdelhi.ac.in/assets/ajmeraward2.jpeg",
          des: "",
        },
      ],
    },
  ];

  const school = (
    <div className="halloffame__container">
      {dataSchoolAwards.map((item) => (
        <div className="halloffame__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="halloffame__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>

          <div
            className="halloffame__card__text"
            dangerouslySetInnerHTML={{ __html: item.des }}
          ></div>
        </div>
      ))}
    </div>
  );

  const teacher = (
    <div className="halloffame__container">
      {dataTeacherAwards.map((item) => (
        <div className="halloffame__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="halloffame__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item.img}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>

          <div className="halloffame__card__text">{item.des}</div>
        </div>
      ))}
    </div>
  );

  const acheivements = (
    <div className="halloffame__container">
      {dataAcheivements.map((item) => (
        <div className="halloffame__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="halloffame__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>

          <div className="halloffame__card__text">{item.des}</div>
        </div>
      ))}
    </div>
  );

  let uniformJSX = null;

  switch (currentIndex) {
    case 0:
      uniformJSX = school;
      break;
    case 1:
      uniformJSX = teacher;
      break;
    case 2:
      uniformJSX = acheivements;
      break;
    default:
      uniformJSX = school;
  }

  return (
    <Layout>
      <SEO
        title="Hall of Fame | Chinmaya Vidyalaya"
        description="Explore the accolades and prestigious awards received by the faculty and school."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="halloffame">
        <h1 className="heading">Hall Of Fame</h1>

        <div className="halloffame__tabs">
          <div
            className={`halloffame__tabs__button ${
              currentIndex === 0 && "halloffame__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(0)}
          >
            School Awards
          </div>
          <div
            className={`halloffame__tabs__button ${
              currentIndex === 1 && "halloffame__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(1)}
          >
            Teacher Awards
          </div>
          <div
            className={`halloffame__tabs__button ${
              currentIndex === 2 && "halloffame__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(2)}
          >
            Achievements
          </div>
        </div>

        {uniformJSX}
      </div>
    </Layout>
  );
}
